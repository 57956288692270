/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Container from "../components/container"
// styles


// markup
const Checkout = () => {
  return (
    <Layout>
        <Container>
            <title>Checkout Redirecting...</title>
            <div
            sx={{
                padding:"145px 0px",
            }}
            >
            <h1
            sx={{
                fontSize: ["2.75rem", "3.5rem"],
                margin: "0px 0px 10px 0px",
                lineHeight: "3.4rem",
                maxWidth:["100%", "100%", "600px"],
              }}
            >Please wait.</h1>
            <p
            sx={{
                color: "textGray",
                fontSize: "body",
                lineHeight: "2.2rem",
                maxWidth:["100%", "100%", "600px"],
                margin:"10px 0px",
              }}
            >
            You are being redirected to your checkout.
            </p>
            </div>
        </Container>
      </Layout>
  )
}

export default Checkout
